export default [
  {
    path: "/all-employees",
    name: "all-employees",
    component: () =>
      import(/* webpackChunkName: "directory" */ "../views/AllEmployees.vue"),
    meta: { auth: true }
  },
  {
    path: "/add-employee",
    name: "add-employee",
    component: () =>
      import(/* webpackChunkName: "directory" */ "../views/AddEmployee.vue"),
    meta: { auth: true }
  },
  {
    path: "/create-employee-onboarding",
    name: "create-employee-onboarding",
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "../views/EmployeeOnboarding.vue"
      ),
    meta: { auth: true }
  },
  {
    path: "/welcome",
    name: "employee-onboarding-welcome",
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "../views/EmployeeOnboardingPreview.vue"
      ),

    meta: { auth: true }
  },
  {
    path: "/onboarding-form",
    name: "onboarding-form",
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "../components/UserEmployeeInformationForm.vue"
      ),

    meta: { auth: true }
  },
  {
    path: "/introduction-form",
    name: "introduction-form",
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "../components/UserIntroductionForm.vue"
      ),

    meta: { auth: true }
  },
  {
    path: "/view-employee/:id/personal",
    name: "view-employee",
    component: () =>
      import(/* webpackChunkName: "directory" */ "../views/ViewEmployee.vue"),
    meta: { auth: true },
    children: [
      {
        path: "payslip/:payslipId",
        name: "PayslipDetails",
        component: () =>
          import("../views/employeePayslips/EmployeePayslipDetails.vue")
      },
      {
        path: "",
        name: "Personal Information",
        component: () =>
          import("../views/personalInformation/PersonalInformation.vue")
      },
      {
        path: "notes",
        name: "Notes",
        component: () => import("../views/assessment/HrNotes.vue")
      },

      {
        path: "performance",
        name: "Performance Information",
        component: () => import("../views/assessment/AssessmentComponent.vue")
        // children:[
        //   {
        //     path:"",
        //     name:"Assessment",
        //     component: () =>
        //     import("../views/personalInformation/PersonalInformation.vue")
        //   },
        // ]
      },
      {
        path: "bonus",
        name: "Bonus",
        component: () =>
          import(
            /* webpackChunkName: "overview-analytics" */ "../views/bonus/BenefitComponent.vue"
          )
      },

      {
        path: "work",
        name: "Work Information",
        component: () => import("../views/workInformation/WorkInformation.vue")
      },
      {
        path: "folders",
        name: "Folders",
        component: () =>
          import("../views/employeeFolders/employeeFoldersView.vue")
      },
      {
        path: "loans",
        name: "Loans",
        component: () => import("../views/employeeLoan/EmployeeLoans.vue")
      },
      {
        path: "payslips",
        name: "Payslips",
        component: () =>
          import("../views/employeePayslips/EmployeePayslips.vue"),
        children: []
      },
      {
        path: "leaves",
        name: "Leaves",
        component: () => import("../views/employeeLeaves/EmployeeLeaves.vue")
      },
      {
        path: "projects",
        name: "Projects",
        component: () =>
          import("../views/employeeProjects/EmployeeProjectView.vue")
      },
      {
        path: "projects-details/:projectId",
        name: "EmployeesProjectsDetails",
        component: () =>
          import("../views/employeeProjects/EmployeeProjectDetails.vue")
      },
      {
        path: "/view-employee/:id/personal/time-tracker",
        name: "Time-tracker",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "personal-timetracker" */ "@/apps/time-tracker/views/TimeTracking.vue"
          ),
        children: [
          {
            path: "clock-it",
            name: "Clock-it",

            component: () =>
              import(
                /* webpackChunkName: "clock-it" */ "@/apps/time-tracker/components/timeTrackerComponents/ClockIt.vue"
              )
          },
          {
            path: "task-planner",
            name: "Task-planner",
            component: () =>
              import(
                /* webpackChunkName: "task-planner" */ "@/apps/time-tracker/components/task-planner-components/TaskPlanner.vue"
              )
          },
          {
            path: "preference",
            name: "Preference",
            component: () =>
              import(
                /* webpackChunkName: "preference" */ "@/apps/time-tracker/components/preference-components/Preference.vue"
              )
          }
        ]
      }
    ]
  },
  {
    path: "/view-employee/:id/account-settings",
    name: "account-settings",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "../views/accountSettings/AccountSettings.vue"
      )
  },
  {
    path: "/edit-profile/:id",
    name: "edit-profile",
    component: () =>
      import(/* webpackChunkName: "directory" */ "../views/EditProfile.vue"),
    meta: { auth: true }
  },
  {
    path: "/performance-improvement-plan/:id",
    name: "performancePlan",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "../views/assessment/components/PipStepperComponent.vue"
      )
  },
  {
    path: "/overview-analytics/:id",
    name: "OverviewAnalytics",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "overview-analytics" */ "../views/assessment/AssessmentOverviewAnalytics.vue"
      )
  }
];
