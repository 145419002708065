export default [
  {
    path: "/configurations",
    name: "Settings",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "config" */ "../views/SettingsView.vue"),
    children: [
      {
        path: "/departments",
        name: "departments",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../components/DepartmentComponent.vue"
          )
      },
      {
        path: "/employeedata",
        name: "EmployeeData",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../components/EmployeeData.vue"
          )
      },
      {
        path: "/positions",
        name: "positions",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../components/EmployeePosition.vue"
          )
      },
      {
        path: "/employment-types",
        name: "Employment Types",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../components/EmploymentTypes.vue"
          )
      },
      {
        path: "/idprefix",
        name: "Id Prefix",
        meta: { auth: true },
        component: () =>
          import(/* webpackChunkName: "config" */ "../views/IdPrefix.vue")
      },
      {
        path: "/access-level",
        name: "Access Level",
        meta: { auth: true },
        component: () =>
          import(/* webpackChunkName: "config" */ "../views/AccessLevel.vue")
      },
      {
        path: "/create-access-level",
        name: "Create Access Level",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/CreateAccessLevel.vue"
          )
      },
      {
        path: "/seniority-level",
        name: "Seniority Level",
        meta: { auth: true },
        component: () =>
          import(/* webpackChunkName: "config" */ "../views/SeniorityLevel.vue")
      },
      {
        path: "/updateaccesslevel",
        name: "Update Access Level",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/CreateAccessLevel.vue"
          )
      }
    ]
  }
];
