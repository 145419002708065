<template>
  <div
    class="N700 flex justify-end pt-5 content-center"
    @mouseleave="removeDropdowns"
  >
    <div class="flex relative gap-4">
      <div
        v-if="displayedApps"
        class="
          self-center
          cursor-pointer
          flex
          gap-2
          bg-[#D8DAE5]
          hover:bg-[#bbbbd0]
          p-2
          rounded-lg
        "
        @click="showAppsDropdown.show = !showAppsDropdown.show"
      >
        <img src="../assets/menu-icons/apps.svg" class="appsIcon" alt="apps icon" />
        <p class="text-[#101840]">Apps</p>
      </div>
      <div
        class="flex gap-4 cursor-pointer"
        @click="showProfiledropdown = !showProfiledropdown"
      >
        <ProfileImageComponent
          :imgSrc="profilePicture"
          :initials="nameToInit(username)"
          :border="'0'"
        />
        <p class="self-center N800 username">{{ username }}</p>
        <img
          src="@/assets/arrow-down.svg"
          class="cursor-pointer h-fit self-center"
          alt=""
        />
      </div>
      <ProfileDropdown v-if="showProfiledropdown" />
      <AppsNavigation v-if="showAppsDropdown.show" :show="showAppsDropdown.show"/>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import { useStore } from "@/store/index";
import ProfileDropdown from "./ProfileDropdown.vue";
import AppsNavigation from "./AppsNavigation.vue";
import PerformanceSvg from "../assets/menu-icons/PerformanceSvg.vue";
import ResourceSvg from "../assets/menu-icons/ResourceSvg.vue";
import EmployeeManagerSvg from "../assets/menu-icons/EmployeeManagerSvg.vue";
import ContractSvg from "../assets/menu-icons/ContractSvg.vue";
import ClockITSvg from "../assets/menu-icons/ClockITSvg.vue";
import PayrollSvg from "../assets/menu-icons/PayrollSvg.vue";
import InvoiceSvg from "../assets/menu-icons/InvoiceSvg.vue";
import ProcurementSvg from "../assets/menu-icons/ProcurementSvg.vue";
import LeaveSvg from "../assets/menu-icons/LeaveSvg.vue";
import IncidenceManagerSvg from "../assets/menu-icons/IncidenceManagerSvg.vue";
import MeetingRoomBookingSvg from "../assets/menu-icons/MeetingRoomBookingSvg.vue";
import ProfileImageComponent from "@/components/Avatar/ProfileImageComponent.vue";
import { nameToInit } from "@/helpers/utils";
import config from "@/config/config";


interface AppType {
  text: string;
  link?: string;
  icon: typeof PerformanceSvg;
  key: string
}


const store = useStore();
const permitedApps = computed(() => (store?.user?.apps)); 


const apps: AppType[] = [
  { text: "Performance manager", link: config.performanceLink, icon: PerformanceSvg, key:"performance management" },
  { text: "Resource manager", link: config.resourceLink, icon: ResourceSvg, key:"resource management" },
  { text: "Employee manager", link: config.employeeManagerLink, icon: EmployeeManagerSvg, key:"EMS" },
  { text: "Contract manager", link: config.contractManagerLink, icon: ContractSvg, key:"contract management" },
  { text: "Clock-IT", link: config.clockItLink, icon: ClockITSvg, key:"time tracker" },
  { text: "Payroll", link: config.payrollLink, icon: PayrollSvg, key:"payroll" },
  { text: "Invoice manager", link: config.invoiceManagerLink, icon: InvoiceSvg, key:"invoice manager" },
  { text: "Procurement manager", link: config.procurementManagerLink, icon: ProcurementSvg, key:"procurement manager" },
  { text: "Leave manager", link: config.leaveManagerLink, icon: LeaveSvg, key:"leave management" },
  { text: "Loan manager", link: config.loansManagerLink, icon: PayrollSvg, key:"loans" },
  { text: "Incidence manager", link: config.incidenceManagerLink, icon: IncidenceManagerSvg, key:"incidence manager" },
  { text: "Meeting room booking", link: config.meetingRoomBookingLink, icon: MeetingRoomBookingSvg, key:"meeting booking" },
];


const displayedApps = computed(() => {
  return apps.some(app => permitedApps.value?.includes(app.key));
});

const username = computed(()=>(store.userName));
const profilePicture = computed(()=> (store.profilePicture));
const showProfiledropdown = ref<boolean>(false);
const showAppsDropdown = reactive({show:false});
const removeDropdowns = () => {
  showAppsDropdown.show = false;
  showProfiledropdown.value = false;
};


</script>
<style>

@media (max-width: 420px) {
  .profile-img {
    border-radius: 50%;
  }

  .username {
    display: none;
  }
}
</style>