import PageNotFound from "../../PageNotFound.vue";

export default [
  {
    path: "/assessment-manager",
    name: "assessment-managers",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "assessment-manager" */ "../views/AssessmentManager.vue"
      )
  },
  {
    path: "/competency",
    name: "Competency",
    meta: { auth: true },
    component: () =>
      import(
        // The competency component has a multi word name, the reason for the addition of the component to its name
        /* webpackChunkName: "competency" */ "../views/CompetencyView.vue"
      )
  },
  {
    path: "/questionnaire",
    name: "Questionnaire",
    meta: { auth: true },
    component: () =>
      import(
        // The questionnaire component has a multi word name, the reason for the addition of the component to its name
        /* webpackChunkName: "questionnaire" */ "../views/QuestionnaireView.vue"
      )
  },
  {
    path: "/create-questionnaire",
    name: "CreateQuestionnaire",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "create-questionnaire" */ "../views/AddEditQuestionnaireView.vue"
      )
  },
  {
    path: "/questionnaire-edit-mode",
    name: "questionnaire-edit-mode",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "edit-questionnaire" */ "../views/AddEditQuestionnaireView.vue"
      )
  },
  {
    path: "/assessment-cycle",
    name: "AssessmentCycle",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "assessment-cycle" */ "../views/AssessmentCycle.vue"
      )
  },
  {
    path: "/create-assessment-cycle",
    name: "CreateAssessmentCycle",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "assessment-cycle" */ "../views/AddEditAssessmentCycle.vue"
      )
  },
  {
    path: "/notification",
    name: "NotificationView",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "config" */ "../views/NotificationView.vue")
  },
  {
    path: "/answer-questionnaire",
    name: "AnswerQuestionnaire",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "answer-questionnaire" */ "../views/AnswerQuestionnaireView.vue"
      )
  },
  {
    path: "/direct-report-assessment",
    name: "direct-report-assessment",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "direct-report" */ "../views/DirectReport.vue"
      )
  },
  {
    path: "/self-assessment",
    name: "self-assessment",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "self-assessment" */ "../views/SelfAssessment.vue"
      )
  },
  {
    path: "/peer-assessment",
    name: "peer-assessment",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "peer-assessment" */ "../views/PeerAssessment.vue"
      )
  },
  {
    path: "/peer-assignment",
    name: "peer-assignment",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "peer-assignment" */ "../views/PeerAssignment.vue"
      )
  },
  {
    path: "/peer-assignment/:id",
    name: "peer",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "peer-assignment" */ "../views/PeerAssignmentAssets/AllocatePeer.vue"
      )
  },
  {
    path: "/manager-assessment",
    name: "manager-assessment",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "assessment-manager" */ "../views/ManagerAssessment.vue"
      )
  },
  {
    path: "/view-assessment-group",
    name: "view-assessment-group",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "assessment-group" */ "../views/AssessmentManagerDetails.vue"
      )
  },
  {
    path: "/manager-response",
    name: "manager-assessment-response",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "manager-assessment-response" */ "../components/responses-components/managerAssessmentResponseComponent.vue"
      )
  },
  {
    path: "/peer-response",
    name: "peer-response",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "peer-response" */ "../components/responses-components/peerAssessmentResponseComponent.vue"
      )
  },
  {
    path: "/direct-response",
    name: "direct-response",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "direct-response" */ "../components/responses-components/directReportAssessmentResponseComponent.vue"
      )
  },
  {
    path: "/self-assessment-response",
    name: "self-response",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "self-response" */ "../components/responses-components/selfAssessmentResponseComponent.vue"
      )
  },
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    meta: { auth: true },
    component: PageNotFound
  }
];
