import performanceAssessmentRoutes from "@/apps/performance-assessment/router/performanceAssessmentRoutes";
import settingRoutes from "@/apps/settings-config/router/settingRoutes";
import staffDirectoryRoutes from "@/apps/staff-directory/router/staffDirectoryRoutes";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PageNotFound from "../apps/authentication/components/PageNotFound.vue";
import {
  decodeRouteToken,
  getToken,
  getUserid,
  isLoggedIn,
  setToken
} from "../services/auth";
import { encodeUrlPath } from "@/helpers/utils";
import { useStore } from "@/store";

const route: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () =>
      (window.location.href = `${env.VUE_APP_MAIN_PAGE_URL}login`),
    // (window.location.href = `${env.VUE_APP_SSO}/login`),
    meta: { auth: false }
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ "../apps/authentication/components/ForgotPassword.vue"
      )
  },
  {
    path: "/sending-email",
    name: "SendingEmail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../apps/authentication/components/SendingEmail.vue"
      )
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../apps/authentication/components/ChangePassword.vue"
      )
  },
  {
    path: "/create-password",
    name: "CreatePassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../apps/authentication/views/createPassword.vue"
      )
  },
  {
    path: "/myglobalcomponentsarehere",
    name: "ShowGlobal",
    component: () =>
      import(/* webpackChunkName: "global" */ "@/ui-kit/GlobalComponents.vue")
  },
  {
    path: "/legacy",
    name: "legacy",
    component: () =>
      (window.location.href = `${
        process.env.VUE_APP_ERP_API
      }/auth?token=${getToken()}`),
    //  import(/* webpackChunkName: "legacy" */ "@/apps/HomePage.vue"),
    meta: { auth: true },
    beforeEnter: (_to, _from, next) => {
      if (isLoggedIn()) {
        window.location.href = `${
          process.env.VUE_APP_ERP_API
        }/auth?token=${getToken()}`;
      } else {
        next();
      }
    }
  },

  {
    path: "/",
    alias: "/home",
    name: "home",
    meta: { auth: true },
    // redirect: { name: "all-employees" },
    component: () =>
      import(
        /* webpackChunkName: "signin" */ "@/apps/staff-directory/views/AllEmployees.vue"
      )
  },

  {
    path: "/notification",
    name: "NotificationView",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "config" */ "../apps/performance-assessment/views/NotificationView.vue"
      )
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ "../apps/authentication/components/ForgotPassword.vue"
      )
  },
  {
    path: "/add-employee",
    name: "add-employee",
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "../apps/staff-directory/views/AddEmployee.vue"
      ),
    meta: { auth: true }
  },
  {
    path: "/edit-profile/:id",
    name: "edit-profile",
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "../apps/staff-directory/views/EditProfile.vue"
      ),
    meta: { auth: true }
  },

  {
    path: "/sending-email",
    name: "SendingEmail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../apps/authentication/components/SendingEmail.vue"
      )
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../apps/authentication/components/ChangePassword.vue"
      )
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../apps/authentication/components/ResetPassword.vue"
      )
  },
  {
    path: "/learnmore",
    name: "Learn More",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "config" */ "../apps/staff-directory/components/LearnMore.vue"
      )
  },

  {
    path: "/migration-service",
    name: "Migration",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "config" */ "../apps/migration-service/views/MigrationPage.vue"
      )
  },
  {
    path: "/add-query/:id",
    name: "Query",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "config" */ "../apps/migration-service/views/AddQuery.vue"
      )
  },
  {
    path: "/user-profile",
    name: "user-profile",
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal`)
  },
  {
    path: "/request-loan",
    name: "request-loan",
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal/loans`)
  },

  {
    path: "/create-access",
    name: "create-access",
    meta: { auth: true },
    component: () =>
      import("../apps/settings-config/views/CreateAccessLevel.vue")
  },
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { auth: true }
  }

  /** End of Auth routes */
];

const env = process.env;

export const routes = route
  .concat(performanceAssessmentRoutes)
  .concat(settingRoutes)
  .concat(staffDirectoryRoutes);

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, _from, next) => {
  if (to.query["app-token"]) {
    const appToken = to.query["app-token"];
    const decodedToken = decodeRouteToken(appToken as string);
    const loginHint = to.query["login-hint"] as string;
    const path = to.path;
    setToken(appToken as string);
    useStore()?.setUser(decodedToken);
    useStore()?.setId(decodedToken.user_id);
    useStore()?.setName(decodedToken.first_name ?? "");
    useStore()?.setProfilePicture(decodedToken.profile_image);
    useStore()?.setLoginHint(loginHint);
    next(path);
  } else if (to.meta.auth && !isLoggedIn()) {
    const redirect = `${window.location.protocol}//${window.location.host}`;
    window.location.href = `${env.VUE_APP_MAIN_PAGE_URL}sso-login?redirect-to=${redirect}`;
  } else {
    next();
  }
});

export default router;
